a {
	text-decoration: none;
}

.appStyle {
	width: 100%;
	height: calc(100vh);
	background-blend-mode: multiply;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.lightBackground {
	background-color: #c3c3c3;
}

.darkBackground {
	background-color: #262626;
}

.image1 {
	background-image: url("./background.jpg");
}

.image2 {
	background-image: url("./background2.jpg");
}

.headerStyle {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 7vh;
	padding-left: 0.5vw;
	padding-right: 0vw;
	margin-left: 2vw;
	margin-right: 2vw;
	border-bottom: 2px solid #FFF;
	box-shadow: 0px 1px rgba(0, 0, 0, 0.1);
	position: sticky;
	top: 0;
}

.headerLeftStyle {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	font-size: 1.6rem;
}

.headerRightStyle {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	font-size: 1.1rem;
}

.headerIcon {
	height: 6.5vh;
}

.headerContent {
	height: 100%;
	padding-left: 1vw;
	padding-right: 1vw;
	padding-top: 1vh;
	padding-bottom: 1vh;
	color: #fff;
	mix-blend-mode: difference;
	text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
	font-family: 'Spinnaker', sans-serif;
}

.headerContentHover:hover {
	border-radius: 25%;
	background-color: rgba(255, 255, 255, 0.1);
}

.bodyStyle {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.bodyLeft {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	padding-left: 1vw;
	width: 20vw;
}

.bodyCenter {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 75vh;
	flex-grow: 1;
	/* border-radius: 1%; */
}

.bodyRight {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-end;
	padding-right: 1vw;
	width: 20vw;
}

.bodyActionStyle {
	transition: padding 200ms, blur 100ms, background-color 200ms;
	transition-timing-function: ease-out;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	text-decoration: 'none';
}

.bodyActionCircle {
	transition: padding 300ms;
	width: 2vh;
	height: 2vh;
	background-color: rgba(255, 255, 255, 0.4);
	border-radius: 50%;
	margin: 1vh 0;
}

.bodyActionText {
	padding: 0.5vh;
	color: #FFF;
	text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
	font-size: 1.1rem;
	font-family: 'Spinnaker', sans-serif;
	display: none;
	padding-left: 1.5vh;
}

.bodyActionStyle:hover {
	border-radius: 10%;
	padding: 0 1vw;
	background-color: rgba(255, 255, 255, 0.05);
	backdrop-filter: blur(2px);
	-webkit-backdrop-filter: blur(2px);
}

.bodyActionStyle:hover .bodyActionCircle {
	padding-left: 10wv;
	margin: 1vh 0;
	background-color: rgba(255, 255, 255, 0.8);
}

.bodyActionStyle:hover .bodyActionText {
	display: flex;
}

.bodyCenterStyle {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	text-decoration: 'none';
	padding: 2vh 0;
}

.bodyCenterText {
	padding: 0.5vh;
	color: #FFF;
	text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
	font-size: 1.1rem;
	font-family: 'Spinnaker', sans-serif;
	padding-left: 1.5vh;
}

.bodySocialStyle {
	transition: transform 150ms, blur 100ms, background-color 150ms;
	transition-timing-function: ease-out;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	text-decoration: 'none';
}

.bodySocialText {
	padding: 0.5vh;
	color: #FFF;
	text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
	font-size: 1.1rem;
	font-family: 'Spinnaker', sans-serif;
	display: none;
	padding-left: 1.5vh;
}

.bodySocialIcon {
	height: 7.5vh;
	padding: 1.5vh;
}

.bodySocialStyle:hover {
	transform: translateX(-10px);
	border-radius: 10% 25%;
	background-color: rgba(255, 255, 255, 0.05);
	backdrop-filter: blur(2px);
	-webkit-backdrop-filter: blur(2px);
}

.bodySocialStyle:hover .bodySocialText {
	display: flex;
}

.bodySocialStyle:hover .bodySocialIcon {
	padding-right: 10wv;
}

.footerStyle {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-end;
	padding-right: 1vw;
}

.footerContent {
	transition: text-decoration 1000ms;
	padding: 0.5vh;
	color: #FFF;
	text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
	font-size: 1.4rem;
	font-family: 'Spinnaker', sans-serif;
}

.footerContentHover {
	display: inline-block;
	position: relative;
	color: #FFF;
}
  
.footerContentHover:after {
	content: '';
	position: absolute;
	width: 100%;
	transform: scaleX(0);
	height: 2px;
	bottom: 0;
	left: 0;
	background-color: #FFF;
	transform-origin: bottom right;
	transition: transform 400ms ease-out;
}
  
.footerContentHover:hover:after {
	transform: scaleX(1);
	transform-origin: bottom left;
}

.RightSideBarStyle {
	position: fixed;
	right: 0;
	padding-left: 3vw;
	padding-top: 5vh;
	width: 25vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.8);
}

.RightSideBarText {
	color: whitesmoke;
}

.RightSideBarText:hover {
	color: rgba(245, 245, 245, 0.7)
}
